input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 2.5;
  padding: 2px;
  color: "red" !important;
  letter-spacing: 0.03rem;
  font-weight: 500 !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* cubic-bezier(0.64, 0.09, 0.08, 1) */
}

.floating-label {
  letter-spacing: 0.02rem;
  font-size: 14px !important;
  line-height: 1.5;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
